import Select, { StylesConfig } from 'react-select';
import { OptionTypeBase } from 'react-select/src/types';
import React from 'react';
import { Props } from 'react-select/src/Select';

const customStyles: StylesConfig<OptionTypeBase, boolean> = {
  // @ts-ignore
  container: (provided, state) => ({
    ...provided,
    marginBottom: 19
  }),
  // @ts-ignore
  input: (provided, state) => ({
    ...provided,
    height: '40px',
    padding: '8px 0 0',
    marginLeft: 12
  }),
  // @ts-ignore
  placeholder: (provided, state) => ({
    ...provided,
    marginLeft: 12
  }),
  // @ts-ignore
  indicatorSeparator: (provider, state) => ({
    ...provider,
    display: 'none'
  }),
  // @ts-ignore
  singleValue: (provided, state) => ({
    ...provided,
    marginLeft: 8
  })
};

const SelectRS = (props: Props<any>) => {
  if (props.error) {
    // @ts-ignore
    customStyles.control = (provided, state) => ({
      ...provided,
      borderColor: '#EC4210'
    });
  } else {
    // @ts-ignore
    customStyles.control = (provided, state) => ({
      ...provided,
      borderColor: '#A5A5A5'
    });
  }
  return (
    <Select
      {...props}
      theme={(theme) => ({
        ...theme,
        borderRadius: 4,
        colors: {
          ...theme.colors,
          primary25: '#ffffff',
          primary: '#A5A5A5'
        }
      })}
      styles={customStyles}
    />
  );
};

export default SelectRS;
