const translation = {
  adult: 'Взрослый',
  adults: 'Взрослых',
  child: 'Ребенок',
  infant: 'Младенец',
  ticket: {
    select: 'Выберите билет',
    clear: 'Сбросить выбор',
    child: 'Ребенок',
    infant: 'Младенец',
    perCar: 'За Группу',
    selected: 'Выбранный',
    moreInfo: 'Подробнее',
    adultToolTip: '{{age}}+ лет',
    childToolTip: 'Меньше {{age}} лет',
    adultTooltip: '12 + лет',
    childTooltip: '5 - 11.99 лет',
    infantTooltip: '0 - 4.99 лет',
    pricePerVehicle: 'Цена за Группу * <1> Максимум 6 человек</1>',
    time: 'Время',
    selectTime: 'Выберите время',
    total: 'Итого',
    checkout: 'Оплата',
    selectDate: 'Чтобы продолжить, выберите дату',
    ticketInformation: 'Информация о билете',
    checkAvailability: 'Проверка наличия',
    generalAdmission: 'Общий билет',
    left: 'Мест',
    openDated: 'Билеты с открытой датой действительны до {{date}}',
  },
  checkout: {
    guest: 'Информация о госте',
    selectHotel: 'Выберите отель',
    enterYourName: 'Введите ваше имя',
    enterYourRoom: 'Укажите номер комнаты',
    enterYourEmail: 'Ваш Email',
    selectMobile: 'Номер телефона',
    bookingSummary: 'Информация о бронировании',
    tourDate: 'Дата',
    pickUpTime: 'Во сколько вас заберут',
    selectHotelFirst: 'Сначала необходимо выбрать отель',
    ticketType: 'Тип билета',
    numberOfPeopleLabel: 'Количество',
    cancellationPolicyLabel: 'Условия отмены',
    cancellationPolicy: '48 часов - 100% / 24 часа - 50%',
    clock1: 'час',
    clock2: 'часа',
    clock3: 'часов',
    orMore: 'или больше до начала',
    returns: 'возврат',
    validUntil: 'Билеты действительны до',
    perPerson: 'За Человека',
    completeGuestDetails: 'Заполните детали',
    invalidHotel: 'Выбранная вами комбинация тура и билета действительна только из {{cities}}. Отель, который вы выбрали, находится в {{cityName}}. Пожалуйста, свяжитесь с нами по телефону {{supportPhoneNumber}} для оформления бронирования.',
    hotelNotListed: 'Если ваш отель отсутствует в списке или ваше место отправления не является отелем, пожалуйста, свяжитесь с нами по телефону {{supportPhoneNumber}}.',
    nonRefundable: 'Билеты не подлежат возврату',
    total: "К Оплате",
    noTimeSlots: "Нет свободных мест"
  },
  payment: {
    cardNumber: 'Номер карты',
    expirationDate: 'Срок действия карты',
    cvc: 'CVC',
    bookNow: 'Оплатить {{amount}} {{currency}}',
    bookNowSbp: 'Оплатить через СБП {{amount}} руб',
    bookNowCard: 'Оплатить картой РФ {{amount}} руб (+2%)',
    purchaseAgreement: 'Совершая покупку, вы принимаете условия отмены',
    masterCardAndVisa: 'Банковской картой',
    masterCardAndVisaDescription: 'Данные вашей карты защищены 2048-битным SSL-шифрованием.',
    paymentProcessing: 'Платеж Обрабатывается...',
    ruCardPaymentTitle: 'Оплатить картой РФ',
    ruCardPaymentDescription: 'Оплата через Модульбанк. Принимаются все основные карты Российской Федерации.',
    personalInfo: 'Пожалуйста введите свою личную информацию, чтобы завершить бронирование',
    payInAed: 'Вы заплатите в AED. Цена, указанная в {{currency}}, является приблизительной. Эмиссионный банк может иметь другой обменный курсы и комиссию за зарубежную транзакцию.'
  },
  confirm: {
    title: 'Бронирование подтверждено',
    paymentFailed: 'Бронирование не подтверждено. Платеж не прошел.',
    paymentHookError: 'Произошла ошибка, пожалуйста свяжитесь с нашим отделом бронирований и укажите ID бронирования.',
    tourName: 'Название тура',
    totalCost: 'Общая стоимость',
    guestName: 'Имя гостя',
    hotelName: 'Имя отеля',
    roomNumber: 'Номер комнаты',
    mobileNumber: 'Номер телефона',
    email: 'Электронная почта',
    ticketInfo: 'Копия ваучера была отправлена на указанный адрес электронной почты.',
    downloadVoucher: 'Скачать ваучер',
    downloadTickets: 'Скачать билеты',
    downloadInvoice: 'Скачать счет',
    information: 'В случае, если указанная информация неверна или у вас есть дополнительные вопросы, свяжитесь с нами свяжитесь с нами по указанным ниже контактам.',
    reservationTeamContacts: 'Контакты отдела бронирования',
    questions: 'Вы можете связаться с нами по любым вопросам',
    bookingStatus: 'Статус бронирования',
    bookingId: 'ID бронирования',
    contact: 'Зенфира',
    pleaseWait: 'Пожалуйста, подождите, ваш платеж обрабатывается',
  },
  faq: {
    title1: 'Могу ли оплатить наличными на месте вместо карты?',
    body1: 'Бронирование осуществляется по предоплате, за исключением особых случаев. Вы можете связаться с нами, кликнув по иконке контактов в шапке. ',
    title2: 'Могу ли я вернуть деньги в случае отмены?',
    body2: 'Да, если вы хотите отменить бронирование, пожалуйста, свяжитесь с нами.\n {{cancellationPolicy}} Возврат денежных средств осуществляется за вычетом комиссии за проведение платежа, которая зависит от банка и платежной системы и составляет 2-3% от суммы. \n',
    cancellationPolicy48: 'В случае отмены бронирования за более чем 48 часов возвращается {{percent}}% стоимости. \n',
    cancellationPolicy24: 'В случае отмены бронирования за более чем 24 часа но менее чем за 48 часов, возвращается {{percent}}% стоимости. \n',
    title3: 'Куда мне необходимо явиться?',
    body3: 'Если вы приобрели билет, который включает трансфер и отеля, тогда вас заберут из отеля в указанное время сбора.\nЕсли вы приобрели билет без трансфера, вам будет необходимо самостоятельно добраться до указанного места к 20.30. Подробная данные о местоположении будут указаны в ваучере, который вы получите после оплаты.',
    title4: 'Что включено в билет?',
    body4: 'Все билеты включают All tickets include a cruise as well as a full course dinner and soft drinks. If you have purchased a package which includes unlimited house drinks, then you will have unlimited drinks from our bar.\nIf not, you will be able to order drinks from the bar at an extra charge.',

  }

}

export default translation;