
import { Hotel } from './hotel';
import { Availability  } from './availability';

export type TicketType = {
  id: number;
  name: string;
  heroImageId: number;
  heroImage: HeroImageType;
  offers: OfferType[];
  price: number;
  priceBeforeDiscount?: number;
  utcOffset: number;
  faq: string;
  cancellationPolicy?: CancellationPolicy[];
  url: string;
  photos: HeroImageType[];
  tourItinerary: string;
  seoContent: string;
  termsAndConditions: string;
  seoPageDescription: string;
  seoPageTitle: string;
  salesDescription: string;
  videoUrl: string;
  languageId: number;
  totalRating: number;
  totalRatingsCount: number;
  cityName: string;
  faqList: FaqType[];
  currency: string;
  isExternal: boolean
};

export type FaqType = {
  answer: string;
  index: number;
  question: string;
};
export type CheckoutData = {
  ticketName: string;
  offer: OfferType | undefined;
  selectedPrice?: PricingPeriods;
  selectedDate?: Date;
  adultCount: number;
  childCount: number;
  infantCount: number;
  timeSlot?: TimeSlotType;
  hotel?: Hotel;
  pickUpTime?: PickUpTime;
  guestName?: string;
  userEmail?: string;
  reconfirmUserEmail?: string;
  userRoomNumber?: string;
  userPhone?: string;
  remarks?: string;
  tourId?: number;
  tourUrl?: number;
  availability?: Availability;
  faq?: string;
  faqList?: FaqType[];
  cancellationPolicy?: CancellationPolicy[];
  ticketStock?: TicketStock;
  pickUpLocation?: string;
};

export type TicketStock = {
  adultTicketsLeft: number;
  childTicketsLeft: number;
  isAdultTicketRequired: boolean;
  isChildTicketRequired: boolean;
  isSameTicketForAdultAndChild: boolean;
  offerId: number;
  validUntil: string | Date;
};

export type HeroImageType = {
  id: number;
  caption: string;
  srcSet: SrcSetType[];
};

export type SrcSetType = {
  type: 'image/webp' | 'image/jpg';
  sizes: ImageSizeType[];
};

export type ImageSizeType = {
  height: number;
  width: number;
  src: string;
};

export type OfferType = {
  id: number;
  bookingType: number;
  name: string;
  childAge: number;
  childrenAllowed: boolean;
  daysOffered: number;
  heroImage: HeroImageType;
  heroImageId: number | null;
  infantAge: number;
  infantsAllowed: boolean;
  isDirectReporting: boolean;
  maxNumOfPeople: number;
  minNumOfPeople: number;
  minimumAdvancedPayment: number | null;
  numberOfHours: number;
  privateType: number;
  prices: PriceType[];
  timeSlots: TimeSlotType[];
  description: string;
  cities: string[];
  isDirectCollection: boolean;
  isExternal: boolean
  pricingPeriods: PricingPeriods[];
};

export type PricingPeriods = {
  id: number;
  currency: string;
  dateEnd: string | Date;
  dateStart: string | Date;
  isBookable: boolean;
  offerId: number;
  pricePerAdult: number;
  pricePerAdultUsd: number;
  pricePerChild: number;
  pricePerChildUsd: number;
  pricePerTrip: number;
  pricePerTripUsd: number;
  recPricePerAdult: number;
  recPricePerAdultUsd: number;
  recPricePerChild: number;
  recPricePerChildUsd: number;
  recPricePerTrip: number;
  recPricePerTripUsd: number;
};

export const BookingTypeEnum = {
  OnRequest: 0,
  LiveBook: 1,
  TicketOnly: 2,
};

export type PriceType = {
  date: string;
  isBookable: boolean;
  offerId: number;
  pricePerAdult: number;
  pricePerAdultUsd: number;
  pricePerChild: number;
  pricePerChildUsd: number;
  pricePerInfant?: number;
  pricePerInfantUsd?: number;
  pricePerTrip: number;
  pricePerTripUsd: number;
};

export type TimeSlotType = {
  daysOffered: number;
  endTime: number;
  id: number;
  interval: number | null;
  isAnyTime: boolean;
  name: string;
  offerId: number;
  startTime: number;
  pickUpTimeId: number;
  minimumAdvancedBooking: number;
  availability?: Availability
};

export type PickUpTime = {
  timeShiftStart: number;
  timeShiftEnd: number;
};

export type CancellationPolicy = {
  hours: number,
  percents: number
}
