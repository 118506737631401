import { fetchRequest } from './index';
import { apiUrl } from '../../config/constants';
import { Availability } from '../../types/availability';
import { addYears, format } from 'date-fns';

const getByTimeSlots = async (timeSlotId: number[] | undefined, from: Date): Promise<Availability[]> => {
  const dateFrom = from <= addYears(new Date(), 1) ? new Date() : from;
  return await fetchRequest.get(
    `${apiUrl}/availability/bytimeslots?from=${format(dateFrom, 'yyyy-MM-dd')}&to=${format(
      addYears(dateFrom, 1),
      'yyyy-MM-dd'
    )}${timeSlotId?.reduce((prev, cur) => `${prev}&ids=${cur}`, '')}`
  );
};

const getByOffer = async (offerId: number, from: Date, to: Date): Promise<Availability[]> => {
  return await fetchRequest.get(
    `${apiUrl}/availability/ByOffer?from=${format(from, 'yyyy-MM-dd')}&to=${format(
      to, 'yyyy-MM-dd'
    )}&id=${offerId}`
  );
};

const availabilityApi = {
  getByTimeSlots,
  getByOffer
};

export default availabilityApi;
