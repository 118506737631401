// config.tsx

interface Config {
  agencyKey: string;
  apiUrl: string;
  stage: string;
  tourId: string;
  appName: string;
  supportPhoneNumber: string;
  supportEmail: string;
  mainSite: string;
  pixelID?: string;
  googleAdsID?: string;
  googleAnalyticsID?: string;
  googleConversionActionID?: string;
  yandexMetricID?: string;
  yandexConversionActionID?: string,
  inspectletID?: string,
  showPhone: boolean;
  rubPayPageUrl?: string;
  language: string;
  currency: string;
  seoTitle: string;
  seoDescription: string;
}

const configs: { [hostname: string]: Config } = {
  'default': {
    agencyKey: 'a46-11b4fb85-57ee-4efa-a9af-d2ea7aad5952',
    apiUrl: 'https://testapi.traveldesk.me/api',
    stage: 'development',
    tourId: '5',
    appName: 'book-dhowcruise',
    supportPhoneNumber: '+971528996310',
    supportEmail: 'dhowcruise@absea.ae',
    mainSite: 'https://dhowcruise.net/',
    pixelID: '625591339562741',
    googleAnalyticsID: 'UA-30174420-6',
    googleAdsID: 'AW-976860031',
    googleConversionActionID: 'AW-976860031/cEaaCIiLs8IDEP_m5tED',
    showPhone: true,
    rubPayPageUrl: 'https://traveldesk-widjets-staging.azurewebsites.net/pay-page',
    language: 'ru',
    currency: 'USD',
    seoTitle: 'Book Seats onboard Alexandra Dhow Cruise',
    seoDescription: 'Book online directly from the official website of Alexandra Floating Restaurant in Dubai Marina.'
  },
  'book.dhowcruise.net': {
    agencyKey: 'a46-11b4fb85-57ee-4efa-a9af-d2ea7aad5952',
    apiUrl: 'https://api.traveldesk.me/api',
    stage: 'production',
    tourId: '5',
    appName: 'book-dhowcruise',
    supportPhoneNumber: '+971528996310',
    supportEmail: 'dhowcruise@absea.ae',
    mainSite: 'https://dhowcruise.net/',
    pixelID: '431582482989370',
    googleAdsID: 'AW-976860031',
    googleAnalyticsID: 'UA-30174420-6',
    googleConversionActionID: 'AW-976860031/cEaaCIiLs8IDEP_m5tED',
    showPhone: true,
    language: 'en', // Default to 'en' if not specified
    currency: 'AED', // Default to 'AED' if not specified
    seoTitle: 'Book Seats onboard Alexandra Dhow Cruise',
    seoDescription: 'Book online directly from the official website of Alexandra Floating Restaurant in Dubai Marina.'
  },
  'book.dhowcruise.com': {
    agencyKey: 'a792-d7bae885-e08b-4560-84a7-e1a5a6f600e8',
    apiUrl: 'https://api.traveldesk.me/api',
    stage: 'production',
    tourId: '5',
    appName: 'book-dhowcruisecom',
    supportPhoneNumber: '+971528996310',
    supportEmail: 'dhowcruise@absea.ae',
    mainSite: 'https://dhowcruise.com/',
    pixelID: '431582482989370',
    googleAdsID: 'AW-976860031',
    googleConversionActionID: 'AW-976860031/cEaaCIiLs8IDEP_m5tED',
    showPhone: true,
    rubPayPageUrl: '',
    language: 'en',
    currency: 'AED',
    seoTitle: 'Book Seats onboard Alexandra Dhow Cruise',
    seoDescription: 'Book online directly from the official website of Alexandra Floating Restaurant in Dubai Marina.'
  },
  'book.miraclegarden.ae': {
    agencyKey: 'a535-b2079733-47f0-4124-9167-0bff40556318',
    apiUrl: 'https://api.traveldesk.me/api',
    stage: 'production',
    tourId: '185',
    appName: 'book-miraclegarden',
    supportPhoneNumber: '+971528996306',
    supportEmail: 'support@traveldesk.me',
    mainSite: 'https://miraclegarden.ae/',
    pixelID: '945862483204921',
    googleAdsID: 'AW-976860031',
    googleConversionActionID: 'AW-976860031/embmCOSFkfUYEP_m5tED',
    showPhone: false,
    rubPayPageUrl: '',
    language: 'en',
    currency: 'AED',
    seoTitle: 'Dubai Miracle Garden Tickets',
    seoDescription: 'Buy Dubai Miracle Garden Tickets Online'
  },
  'book.imgtickets.ae': {
    agencyKey: 'a557-c347f720-b665-4aaa-8929-1aa5b224d5b7',
    apiUrl: 'https://api.traveldesk.me/api',
    stage: 'production',
    tourId: '67',
    appName: 'book-imgtickets',
    supportPhoneNumber: '+971528996306',
    supportEmail: 'support@traveldesk.me',
    mainSite: 'https://imgtickets.ae/',
    pixelID: '945862483204921',
    googleAdsID: 'AW-976860031',
    googleConversionActionID: 'AW-976860031/r0rcCI-8lPcYEP_m5tED',
    showPhone: false,
    rubPayPageUrl: '',
    language: 'en',
    currency: 'AED',
    seoTitle: 'IMG Worlds Of Adventure Theme Park Tickets',
    seoDescription: 'Buy IMG Worlds Of Adventure Theme Park Tickets Online'
  },
  'book.ayauniverse.ae': {
    agencyKey: 'a561-9afc5606-5efb-4795-ad75-084d4b1a2aaa',
    apiUrl: 'https://api.traveldesk.me/api',
    stage: 'production',
    tourId: '20',
    appName: 'book-ayauniverse',
    supportPhoneNumber: '+971528996306',
    supportEmail: 'support@traveldesk.me',
    mainSite: 'https://ayauniverse.ae/',
    pixelID: '945862483204921',
    googleAdsID: 'AW-976860031',
    googleConversionActionID: 'AW-976860031/QMqJCMe3nvsYEP_m5tED',
    showPhone: false,
    rubPayPageUrl: '',
    language: 'en',
    currency: 'AED',
    seoTitle: 'Aya Universe Tickets',
    seoDescription: 'Aya Universe: Join us and be part of a universe where curiosity meets wonder.'
  },
  'book.ayauniverse.ru': {
    agencyKey: 'a995-e79ab37a-0726-4317-a947-3eff595d60bb',
    apiUrl: 'https://api.traveldesk.me/api',
    stage: 'production',
    tourId: '187',
    appName: 'book-ayauniverseru',
    supportPhoneNumber: '+971528996301',
    supportEmail: 'support@traveldesk.me',
    mainSite: 'https://ayauniverse.ru/',
    googleAdsID: 'AW-976860031',
    googleConversionActionID: 'AW-976860031/QMqJCMe3nvsYEP_m5tED',
    yandexMetricID: '98173435',
    yandexConversionActionID: '342841868',
    showPhone: true,
    rubPayPageUrl: 'https://dubaitickets.ru/pay-page',
    language: 'ru',
    currency: 'USD',
    seoTitle: 'Билеты в AYA Dubai',
    seoDescription: 'AYA Dubai: Присоединяйтесь к нам и станьте частью вселенной, где любопытство встречается с чудом.'
  },
  'book.alexandra.ae': {
    agencyKey: 'a541-c6d6447e-4d97-47f8-9897-d148157241fb',
    apiUrl: 'https://api.traveldesk.me/api',
    stage: 'production',
    tourId: '235',
    appName: 'book-alexandra',
    supportPhoneNumber: '+971528996310',
    supportEmail: 'alexandra@absea.ae',
    mainSite: 'https://alexandra.ae/',
    pixelID: '625591339562741',
    googleAdsID: 'AW-976860031',
    googleConversionActionID: 'AW-976860031/i74-CJSDs_AYEP_m5tED',
    inspectletID: '2146822060',
    showPhone: true,
    rubPayPageUrl: '',
    language: 'en',
    currency: 'AED',
    seoTitle: 'Book Seats onboard Alexandra Sea Lounge',
    seoDescription: 'Book online directly from the official website of Alexandra Sea Lounge in Dubai Marina.'
  },
  'book.gardenglow.ae': {
    agencyKey: 'a571-80595d7e-8ad9-4823-82f3-b63645f0e61c',
    apiUrl: 'https://api.traveldesk.me/api',
    stage: 'production',
    tourId: '176',
    appName: 'book-gardenglow',
    supportPhoneNumber: '+971528996306',
    supportEmail: 'support@traveldesk.me',
    mainSite: 'https://gardenglow.ae/',
    pixelID: '945862483204921',
    googleAdsID: 'AW-976860031',
    googleConversionActionID: 'AW-976860031/IH6LCKqn8f8YEP_m5tED',
    showPhone: false,
    rubPayPageUrl: '',
    language: 'en',
    currency: 'AED',
    seoTitle: 'Garden Glow Tickets',
    seoDescription: 'Buy Garden Glow Tickets Online'
  },
  'book.aquarium.tickets': {
    agencyKey: 'a573-823b1e2c-63d3-4814-a861-5c9947a61bb4',
    apiUrl: 'https://api.traveldesk.me/api',
    stage: 'production',
    tourId: '144',
    appName: 'book-dubaiaquarium',
    supportPhoneNumber: '+971528996306',
    supportEmail: 'support@traveldesk.me',
    mainSite: 'https://aquarium.tickets/',
    pixelID: '945862483204921',
    googleAdsID: 'AW-976860031',
    googleConversionActionID: 'AW-976860031/LRMBCL-MgoEZEP_m5tED',
    showPhone: false,
    rubPayPageUrl: '',
    language: 'en',
    currency: 'AED',
    seoTitle: 'Dubai Mall Aquarium Tickets',
    seoDescription: 'Buy Dubai Mall Aquarium Tickets Online'
  },
  'book.thepalmview.com': {
    agencyKey: 'a583-55b6b52f-fbba-4544-b27a-9589d34cb368',
    apiUrl: 'https://api.traveldesk.me/api',
    stage: 'production',
    tourId: '139',
    appName: 'book-thepalmview',
    supportPhoneNumber: '+971528996306',
    supportEmail: 'support@traveldesk.me',
    mainSite: 'https://thepalmview.com',
    pixelID: '945862483204921',
    googleAdsID: 'AW-976860031',
    googleConversionActionID: 'AW-976860031/JpNaCNu63YYZEP_m5tED',
    showPhone: false,
    rubPayPageUrl: '',
    language: 'en',
    currency: 'AED',
    seoTitle: 'The Palm View Tickets',
    seoDescription: 'The View at The Palm Tickets - Stunning Views of Dubai'
  },
  'book.dubaisafari.ru': {
    agencyKey: 'a765-2442ce66-e09e-4a74-9a34-1601c6b90c4a',
    apiUrl: 'https://api.traveldesk.me/api',
    stage: 'production',
    tourId: '86',
    appName: 'book-dubaisafariru',
    supportPhoneNumber: '+971528996301',
    supportEmail: 'support@traveldesk.me',
    mainSite: 'https://dubaisafari.ru',
    pixelID: '945862483204921',
    googleAdsID: 'AW-976860031',
    googleConversionActionID: 'AW-976860031/lP5tCN7D34YZEP_m5tED',
    showPhone: false,
    rubPayPageUrl: 'https://dubaitickets.ru/pay-page',
    language: 'ru', // Assuming language 'ru' for .ru domain
    currency: 'USD', // Assuming USD if not specified
    seoTitle: 'Забронируйте сафари по пустыне в Дубае.',
    seoDescription: 'Сафари по пустыне в Дубае // Катание по дюнам, живая барбекю и шоу'
  },
  'book.dubaisafari.vip': {
    agencyKey: 'a643-0471b682-5893-4769-a3fc-91a7fb287d6e',
    apiUrl: 'https://api.traveldesk.me/api',
    stage: 'production',
    tourId: '159',
    appName: 'book-dubaisafari',
    supportPhoneNumber: '+971528996306',
    supportEmail: 'support@traveldesk.me',
    mainSite: 'https://dubaisafari.vip',
    pixelID: '945862483204921',
    googleAdsID: 'AW-976860031',
    googleConversionActionID: 'AW-976860031/Op8xCKzW34YZEP_m5tED',
    showPhone: true,
    rubPayPageUrl: '',
    language: 'en',
    currency: 'AED',
    seoTitle: 'VIP Desert Safari in Dubai',
    seoDescription: 'Desert Safari in Dubai // Dune Bashing, Live BBQ & Live Show'
  },
  'book.balloonflight.ae': {
    agencyKey: 'a559-5c96fe56-97c9-4fd9-9936-0cad168551c3',
    apiUrl: 'https://api.traveldesk.me/api',
    stage: 'production',
    tourId: '74',
    appName: 'book-balloonflight',
    supportPhoneNumber: '+971528996306',
    supportEmail: 'support@traveldesk.me',
    mainSite: 'https://balloonflight.ae',
    pixelID: '945862483204921',
    googleAdsID: 'AW-976860031',
    googleConversionActionID: 'AW-976860031/brZUCP2g4IYZEP_m5tED',
    showPhone: true,
    rubPayPageUrl: '',
    language: 'en',
    currency: 'AED',
    seoTitle: 'Hot Air Balloon Ride Tickets',
    seoDescription: 'Hot Air Balloon Ride Tickets - Soar Above the Desert'
  },
  'book.helicopterflight.ae': {
    agencyKey: 'a586-54f8f2aa-cacf-4849-bf69-9ca3e6fead31',
    apiUrl: 'https://api.traveldesk.me/api',
    stage: 'production',
    tourId: '76',
    appName: 'book-helicopterflight',
    supportPhoneNumber: '+971528996306',
    supportEmail: 'support@traveldesk.me',
    mainSite: 'https://helicopterflight.ae',
    pixelID: '945862483204921',
    googleAdsID: 'AW-976860031',
    googleConversionActionID: 'AW-976860031/brZUCP2g4IYZEP_m5tED',
    showPhone: true,
    rubPayPageUrl: '',
    language: 'en',
    currency: 'AED',
    seoTitle: 'Book Helicopter Flight In Dubai',
    seoDescription: "Helicopter Flight Palm Jumeirah - A Bird's Eye View of Dubai"
  },
  'book.dubaihelicopter.ru': {
    agencyKey: 'a933-55adb242-1d30-4f23-a8f5-22da24a0db14',
    apiUrl: 'https://api.traveldesk.me/api',
    stage: 'production',
    tourId: '46',
    appName: 'book-dubaihelicopterru',
    supportPhoneNumber: '+971528996301',
    supportEmail: 'support@traveldesk.me',
    mainSite: 'https://dubaihelicopter.ru',
    pixelID: '945862483204921',
    inspectletID: '557849702',
    yandexMetricID: '98657358',
    googleAdsID: 'AW-976860031',
    googleConversionActionID: 'AW-976860031/tQroCObu9t8ZEP_m5tED',
    showPhone: true,
    rubPayPageUrl: 'https://dubaitickets.ru/pay-page',
    language: 'ru',
    currency: 'USD',
    seoTitle: 'Полет на Вертолете В Дубае',
    seoDescription: 'Полёт на вертолёте над Пальмой Джумейра – Вид на Дубай с высоты птичьего полёта'
  },
  'book.dubaitours.ru': {
    agencyKey: 'a49-e389b77d-92c0-46bc-a01a-7fd64080d266',
    apiUrl: 'https://api.traveldesk.me/api',
    stage: 'production',
    tourId: '259',
    appName: 'book-dubaitours',
    supportPhoneNumber: '+971528996301',
    supportEmail: 'web@dubairelaxtours.com',
    mainSite: 'https://dubaitours.ru',
    yandexMetricID: '98657358',
    googleAdsID: 'AW-976860031',
    googleConversionActionID: 'AW-976860031/LRMBCL-MgoEZEP_m5tED',
    showPhone: true,
    rubPayPageUrl: 'https://dubaitickets.ru/pay-page',
    language: 'ru',
    currency: 'USD',
    seoTitle: 'Экскурсии В Дубае',
    seoDescription: 'Купить Билуты на экскурсию в Дубае'
  },
};
  
  export default configs;
  