import * as React from 'react';
import { TimeSlotType } from '../../types/ticket';
import { timeFormatFromTime } from '../../lib/utils';
import { Availability } from '../../types/availability';
import { useTranslation } from 'react-i18next';

type CarouselProps = {
  carouselItems: { timeSlot: TimeSlotType; availability?: Availability }[];
  currentTimeSlot?: TimeSlotType;
  onChange: (timeSlot: TimeSlotType) => void;
  visibleSlides?: number;
  isPerTrip: boolean;
};

const CarouselTimeSlot = ({ carouselItems, currentTimeSlot, onChange, isPerTrip }: CarouselProps) => {
  const [t] = useTranslation();

  return (
    <div className="time-slot-grid">
      {carouselItems.map((carouselItem, index) => {
        const selectedTimeSlot =
          currentTimeSlot &&
          currentTimeSlot.id === carouselItem.timeSlot.id &&
          currentTimeSlot.startTime === carouselItem.timeSlot.startTime;
        return (
          <div
            key={index}
            onClick={() =>
              carouselItem?.availability && carouselItem?.availability?.left > 0 && onChange(carouselItem.timeSlot)
            }
          >
            <div
              className={`carousel__item ${
                selectedTimeSlot && carouselItem?.availability && carouselItem.availability.left > 0
                  ? ' carousel__item_active'
                  : ''
              } ${carouselItem?.availability && carouselItem.availability.left > 0 ? '' : ' carousel__item_disabled'}`}
            >
              {!isPerTrip && (
                <span>
                  {carouselItem.availability?.left} {t('ticket.left')}
                </span>
              )}
              <span>{timeFormatFromTime(carouselItem?.timeSlot?.startTime)}</span>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default CarouselTimeSlot;
