import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { routes } from '../../config/constants';
import Ticket from '../ticket';
import Checkout from '../checkout';
import Confirm from '../confirm';

const EmbeddedLayout = () => {
  return (
    <>
      <div className='container'>
        <Switch>
          <Route exact path={routes.embeddedTicket} component={Ticket} />
          <Route exact path={routes.embeddedCheckout} component={Checkout} />
          <Route exact path={routes.confirm} component={Confirm} />
        </Switch>
      </div>
    </>
  );
};

export default EmbeddedLayout;
