import { useCallback, useEffect, useState } from 'react';
import Offer from './offer';
import { ticketApi } from '../../lib/api';
import { TicketType } from '../../types/ticket';
import 'pure-react-carousel/dist/react-carousel.es.css';
import { useHistory } from 'react-router-dom';
import { routes, mainSite, tourId, currency } from '../../config/constants';
import Button from '../../components/button';
import { ReactComponent as IconRefresh } from '../../assets/images/icons/icon-refresh.svg';
import { addMonths, startOfDay } from 'date-fns';
import { Availability } from '../../types/availability';
import availabilityApi from '../../lib/api/availability';
import { useTranslation } from 'react-i18next';
import { useOffer } from '../../hooks/use-offer';
import { useLocation } from 'react-router-dom';

const handleEventHeaderClick = () => {
  window.location.href = mainSite;
};


const Ticket = () => {
  const [ticket, setTicket] = useState<TicketType>();
  const [availabilities, setAvailabilities] = useState<Availability[]>([]);
  const [loader, setLoader] = useState<boolean>(false);
  const [loaderTimeSlot, setLoaderTimeSlot] = useState<boolean>(false);
  const history = useHistory();
  const [t] = useTranslation();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const Id = queryParams.get('tourId') || tourId || '';

  useEffect(() => {
    const fetchData = async () => {
      const data = await ticketApi.getTickets(currency, Id);
      setTicket(data);
    };
    fetchData();
    document.addEventListener('eventHeaderClick', handleEventHeaderClick, false);
    return () => document.removeEventListener('eventHeaderClick', handleEventHeaderClick, false);
  }, []);

  const fetchAvailability = async (offerId: number, date: Date | undefined, isExternal: boolean,  disableLoading?: boolean,): Promise<Availability[]> =>
  {
    !disableLoading && (isExternal ? setLoaderTimeSlot(true) : setLoader(true));
    let data: Availability[] = [];
    try {
      if (offerId && ((isExternal && date) || !isExternal)) {
        data = await availabilityApi.getByOffer(
          offerId,
          isExternal && date ? startOfDay(date || new Date()) : startOfDay(date || new Date()) <= addMonths(new Date(), 1) ? new Date() : startOfDay(date || new Date()),
          isExternal && date ? startOfDay(date) : addMonths(startOfDay( date || new Date()), 1),
        );

        if (isExternal) {
          setAvailabilities(data.length ? data : [{
            date: date,
            left: 0,
            offerId: offerId,
            startTime: '',
            endTime: ''
          } as Availability])
        } else {
          setAvailabilities((prevState) => [...prevState, ...(data.map(item => ({...item, offerId})))]);
        }
      }
    } finally {
      isExternal ? setLoaderTimeSlot(false) : setLoader(false)
    }
    return data;
  }
  console.log(ticket);
  const {
    editOfferObj,
    selectOffer,
    handleChangePeopleCount,
    selectDate,
    selectTimeSlot,
    clear
  } = useOffer(ticket, availabilities, setLoader, setLoaderTimeSlot, currency, fetchAvailability)

  const handleCheckoutBtn = useCallback(() => {
    if(!editOfferObj.offer?.childrenAllowed){
      editOfferObj.childCount = 0;
    }
    if(!editOfferObj.offer?.infantsAllowed){
      editOfferObj.infantCount = 0;
    }
    history.push(routes.checkout, editOfferObj)
  }, [editOfferObj, history]);


  return (
    <>
      <h1 className='ticket-title'>{ticket?.name}</h1>
      <div className='ticket-select-label'>
        <div>{t('ticket.select')}</div>
        <div>
          {editOfferObj.offer && (
            <Button
              onClickHandler={clear}
              label={t('ticket.clear')}
              size='small'
              color='gray'
              iconLeft={() => <IconRefresh />}
            />
          )}
        </div>
      </div>
      <div className='ticket-wrap'>
      {ticket?.offers
        .sort((a, b) => {
          // Determine if pricePerTrip is null or 0 for each offer
          const aHasPricePerTrip = a.prices[0]?.pricePerTrip != null && a.prices[0]?.pricePerTrip !== 0;
          const bHasPricePerTrip = b.prices[0]?.pricePerTrip != null && b.prices[0]?.pricePerTrip !== 0;

          // If both offers have different pricePerTrip availability
          if (aHasPricePerTrip !== bHasPricePerTrip) {
            // Offers with null or 0 pricePerTrip come first
            return aHasPricePerTrip ? 1 : -1;
          }

          // If both offers have pricePerTrip as null or 0, sort by pricePerAdult
          if (!aHasPricePerTrip && !bHasPricePerTrip) {
            const priceA = a.prices[0]?.pricePerAdult ?? Infinity;
            const priceB = b.prices[0]?.pricePerAdult ?? Infinity;
            return priceA - priceB;
          }

          // If both offers have valid pricePerTrip, sort by pricePerTrip
          const priceA = a.prices[0]?.pricePerTrip ?? Infinity;
          const priceB = b.prices[0]?.pricePerTrip ?? Infinity;
          return priceA - priceB;
        })
        .map((offer) => (
          <Offer
            key={offer.id}
            offer={offer}
            isSelected={offer.id === editOfferObj.offer?.id}
            selectHandler={selectOffer}
            heroImage={ticket.heroImage}
            editOfferObj={editOfferObj}
            handleCheckoutBtn={handleCheckoutBtn}
            handleChangePeopleCount={handleChangePeopleCount}
            selectDate={selectDate}
            selectTimeSlot={selectTimeSlot}
            availabilities={availabilities}
            utcOffset={ticket.utcOffset}
            loader={loader}
            loaderTimeSlot={loaderTimeSlot}
            dateStart={offer?.pricingPeriods && offer?.pricingPeriods[0].dateStart}
            dateEnd={offer?.pricingPeriods && offer?.pricingPeriods[offer.pricingPeriods.length - 1].dateEnd}
            fetchAvailability={async (start, to) => {
              if (editOfferObj.offer) {
                const currentAvailabilities = availabilities.
                filter(availability => editOfferObj.offer && availability.offerId === editOfferObj.offer.id).
                sort((a, b) => new Date(a.date) > new Date(b.date) ? 1 : -1);
                if(new Date(currentAvailabilities[currentAvailabilities.length - 1].date) <  to)
                {
                  await fetchAvailability(
                    editOfferObj.offer?.id,
                    new Date(currentAvailabilities[currentAvailabilities.length - 1].date),
                    editOfferObj.offer?.isExternal,
                    true
                  );
                }
              }}}
          />
        ))}
    </div>
    </>
  );
};

export default Ticket;
