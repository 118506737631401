import { fetchRequest } from './index';
import { apiUrl } from '../../config/constants';
import { City } from '../../types/city';

const getCities = async (countryId: number, language: string,): Promise<City[]> =>
  await fetchRequest.get(`${apiUrl}/cities/options/${countryId}`, { language });

const citiesApi = {
  getCities
};

export default citiesApi;