import React, { memo, useCallback, useEffect, useState } from 'react';
import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
  PaymentRequestButtonElement,
  useElements,
  useStripe
} from '@stripe/react-stripe-js';
import Button from '../button';
import { bookingApi } from '../../lib/api';
import { BookingRequest } from '../../types/booking';
import { useHistory } from 'react-router-dom';
import { routes } from '../../config/constants';
import Loader from 'react-loader-spinner';
import { PaymentRequest as PR, PaymentRequestPaymentMethodEvent } from '@stripe/stripe-js';
import { useTranslation } from 'react-i18next';
import { currency } from '../../config/constants';

type Props = {
  request: BookingRequest;
  amount: number;
  ticketType: string;
  isValid: boolean;
};

const Payment = ({ request, amount, ticketType, isValid }: Props) => {
  const [succeeded, setSucceeded] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [processing, setProcessing] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [paymentRequest, setPaymentRequest] = useState<PR | null>(null);
  const [isShowAppleGooglePayFailed, setIsShowAppleGooglePayFailed] = useState(false);
  const stripe = useStripe();
  const elements = useElements();
  const history = useHistory();

  const [t] = useTranslation();

  const getPaymentIntent = useCallback(async () => {
    const requestStr = JSON.stringify(request);
    const oldPayment = window.localStorage.getItem('payment');
    if(oldPayment && oldPayment.split('|')[0] === requestStr){
      return JSON.parse(oldPayment.split('|')[1])
    }
    const payment = await bookingApi.withPaymentIntent(request);
    window.localStorage.setItem('payment', `${requestStr}|${JSON.stringify(payment)}`);
    return payment;
  }, [request])


  const handlePaymentMethodReceived = useCallback(
    async (e: PaymentRequestPaymentMethodEvent) => {
      const { paymentIntent, booking } = await getPaymentIntent();
      const payload = await stripe?.confirmCardPayment(
        paymentIntent.clientSecret,
        {
          payment_method: e.paymentMethod.id
        },
        {
          handleActions: false
        }
      );
      if (payload?.error) {
        e.complete('fail');
        return;
      } else {
        e.complete('success');
        history.push(`${routes.confirm}/${booking.guid}`);
      }
    },
    [history, stripe, getPaymentIntent]
  );

  useEffect(() => {
    console.log('paymentRequest.on');

    if (paymentRequest) {
      paymentRequest.on('paymentmethod', async (ev) => {
        await handlePaymentMethodReceived(ev);
      });
      return () => {
        console.log('paymentRequest.off');
        paymentRequest.off('paymentmethod');
      };
    }
  }, [handlePaymentMethodReceived, isValid, paymentRequest]);

  useEffect(() => {
    console.log('stripe.paymentRequest');
    if (stripe) {
      const pr = stripe.paymentRequest({
        country: 'AE',
        currency: 'aed',
        total: {
          label: ticketType,
          amount: Math.floor(amount * 100)
        },
        requestPayerName: true,
        requestPayerEmail: true
      });
      pr.canMakePayment().then((result) => {
        if (result) {
          setPaymentRequest(pr);
        }
      });
    }
  }, [amount, stripe, ticketType]);

  const handleChange = async (event: any) => {
    // Listen for changes in the CardElement
    // and display any errors as the customer types their card details
    setDisabled(event.empty);
    setError(event.error ? event.error.message : '');
  };

  const handleSubmit = async (ev: any) => {
    ev.preventDefault();
    if (!isValid) {
      return;
    }
    if (!stripe || !elements) {
      console.log('Stripe.js has not loaded yet. Make sure to disable form submission until Stripe.js has loaded.');
      return;
    }
    setProcessing(true);

    const payment = await getPaymentIntent();
    if (!payment) {
      setProcessing(false);
      return;
    }
    const cardElement = elements?.getElement(CardNumberElement);
    const payload = await stripe?.confirmCardPayment(payment.paymentIntent.clientSecret, {
      payment_method: {
        // @ts-ignore
        card: cardElement
      }
    });

    if (payload?.error) {
      setError(`Payment failed ${payload.error.message}`);
      setProcessing(false);
    } else {
      setError(null);
      setProcessing(false);
      setSucceeded(true);
      history.push(`${routes.confirm}/${payment.booking.guid}`);
    }
  };

  return (
    <form id='payment-form' className='checkout-box-wrap' onSubmit={handleSubmit}>
      <label className='card-element'>
        <span>{t('payment.cardNumber')}</span>
        <CardNumberElement options={{ ...cardStyle, showIcon: true }} onChange={handleChange} />
      </label>
      <label className='card-element'>
        <span>{t('payment.expirationDate')}</span>
        <CardExpiryElement options={cardStyle} onChange={handleChange} />
      </label>
      <label className='card-element'>
        <span>{t('payment.cvc')}</span>
        <CardCvcElement options={cardStyle} onChange={handleChange} />
      </label>
      <div className='payment-button-container'>
        <Button
          disabled={processing || disabled || succeeded || !isValid}
          submit
          iconLeft={() => (processing ? <Loader type='Circles' color='#FFF' height={20} width={20} /> : null)}
          label={t('payment.bookNow', {amount: amount, currency: 'AED'})}
          size='medium'
          color='blue'
          fullWidth
        />
      </div>
      {currency!='AED' && (
        <span >{t('payment.payInAed', {currency: currency})}</span>
      )}
      {/* Show any error that happens when processing the payment */}
      {error && (
        <div className='card-error' role='alert'>
          {error}
        </div>
      )}
      {/* Show a success message upon completion */}
      <p className={succeeded ? 'result-message' : 'result-message hidden'}>
        Payment succeeded, see the result in your
        <a href={`https://dashboard.stripe.com/test/payments`}> Stripe dashboard.</a> Refresh the page to pay again.
      </p>

      {paymentRequest && (
        <div className="payment-button-container">
          {/*@ts-ignore*/}

          <PaymentRequestButtonElement
            onClick={(e) => {
              if (!isValid) {
                setIsShowAppleGooglePayFailed(true);
                e.preventDefault();
              }
            }}
            options={{ paymentRequest }}
          />

          {isShowAppleGooglePayFailed && !isValid && <span className="error">{t('payment.personalInfo')}</span>}
        </div>
      )}
    </form>
  );
};

export default memo(Payment);

const cardStyle = {
  style: {
    base: {
      color: '#32325d',
      border: '1px',
      fontSmoothing: 'antialiased',
      fontSize: '18px',
      lineHeight: '26px',
      '::placeholder': {
        color: '#848484'
      }
    },
    invalid: {
      color: '#fa755a',
      iconColor: '#fa755a'
    }
  }
};
