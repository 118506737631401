import React from 'react';
import Footer from '../../components/footer';
import Header from '../../components/header';
import { Route, Switch } from 'react-router-dom';
import { routes } from '../../config/constants';
import Ticket from '../ticket';
import Checkout from '../checkout';
import Confirm from '../confirm';

const Layout = () => {
  return (
    <>
      <Header />

      <div className='container main-container'>
        <Switch>
          <Route exact path={routes.ticket} component={Ticket} />
          <Route exact path={routes.checkout} component={Checkout} />
          <Route exact path={`${routes.confirm}/:bookingId`} component={Confirm} />
        </Switch>
      </div>
      <Footer />
    </>
  );
};

export default Layout;
