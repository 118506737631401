import { agencyGuid, apiUrl } from '../../config/constants';
import { fetchRequest } from './index';
import { Booking, BookingRequest, Fee, PaymentIntent } from '../../types/booking';

const withPaymentIntent = async (
  request: BookingRequest
): Promise<{ booking: Booking; paymentIntent: PaymentIntent }> =>
  await fetchRequest.post(`${apiUrl}/bookings/withpaymentintent?AgencyGuid=${agencyGuid}`, request);

const getBooking = async (bookingId: string): Promise<Booking> =>
  await fetchRequest.get(`${apiUrl}/bookings/${bookingId}`);
const getFee = async (amount: number, currency: string): Promise<Fee> =>
  (await fetchRequest.get(`${apiUrl}/bookings/calculaterubpaymentamount?amount=${amount}&currency=${currency}`)).data;
const convertCurrency = async (amount: number, fromCode: string, toCode: string): Promise<number> =>
  (await fetchRequest.get(`${apiUrl}/currencies/convertcurrency?amount=${amount}&fromCode=${fromCode}&toCode=${toCode}`));
const createBooking = async (request: BookingRequest): Promise<Booking> => {
  console.log(request);
  return await fetchRequest.post(`${apiUrl}/bookings`, request);
}

const bookings = {
  withPaymentIntent,
  getBooking,
  getFee,
  createBooking,
  convertCurrency
};
export default bookings;
