import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import Input from '../../components/input';
import { currency, emptyDate, language, routes, rubPayPageUrl, stripePubKey, supportPhoneNumber } from '../../config/constants';
import { CheckoutData, FaqType  } from '../../types/ticket';
import format from 'date-fns/format';
import addMinutes from 'date-fns/addMinutes';
import { bookingApi, ticketApi } from '../../lib/api';
import SelectRS from '../../components/select-rs';
import { Hotel } from '../../types/hotel';
import Payment from '../../components/payment';
import { Elements } from '@stripe/react-stripe-js';
import Accordeon, { AccordeonItemType } from '../../components/accordeon';
import { BookingRequest, Fee } from '../../types/booking';
import { loadStripe } from '@stripe/stripe-js/pure';
import stripeJs from '@stripe/stripe-js';
import PhoneInput from 'react-phone-input-2';
import { declOfNum, timeFormat } from '../../lib/utils';
import { useTranslation } from 'react-i18next';
import citiesApi from '../../lib/api/cities';
import { City } from '../../types/city';
import Button from '../../components/button';
import Loader from 'react-loader-spinner';

const initialCheckout = {
  ticketName: '',
  offer: undefined,
  selectedDate: new Date(),
  adultCount: 1,
  childCount: 0,
  infantCount: 0
};

const validateEmail = (email: string) => String(email)
  .toLowerCase()
  .match(
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  );

const Checkout = () => {
  const location = useLocation();
  const history = useHistory();
  const [t] = useTranslation();

  // States
  const [hotels, setHotels] = useState<{ label: string, value: number, hotel: Hotel }[]>([]);
  const [checkout, setCheckout] = useState<CheckoutData>(initialCheckout);
  const [filterSelect, setFilterSelect] = useState('');
  const [stripePromise, setStripePromise] = useState<PromiseLike<stripeJs.Stripe | null> | stripeJs.Stripe | null>();
  const [isFormValid, setIsFormValid] = useState({ userEmail: true, userPhone: true, guestName: true, hotel: true });
  const [cities, setCities] = useState<City[] | undefined>();
  const [aedAmount, setAedAmount] = useState<number>();
  const [Fee, setFee] = useState<Fee>();
  const [processingSbp, setProcessingSbp] = useState(false);
  const [processingCard, setProcessingCard] = useState(false);
  const [paymentType, setPaymentType] = useState<number>(rubPayPageUrl ? 0 : 1);
  const assetsUrl="https://traveldeskuae.blob.core.windows.net/assets";

  // Memos
  const filteredHotel = useMemo(() => {
    return hotels.filter((item) => item.label.toLowerCase().startsWith(filterSelect));
  }, [hotels, filterSelect]);

  const { amount, amountUsd } = useMemo(
    () => ({
      amount: checkout.adultCount * (checkout.selectedPrice?.pricePerAdult || 0) +
        checkout.childCount * (checkout.selectedPrice?.pricePerChild || 0) +
        (checkout.selectedPrice?.pricePerTrip || 0),
      amountUsd: checkout.adultCount * (checkout.selectedPrice?.pricePerAdultUsd || 0) +
        checkout.childCount * (checkout.selectedPrice?.pricePerChildUsd || 0) +
        (checkout.selectedPrice?.pricePerTripUsd || 0)
    }),
    [checkout.adultCount, checkout.childCount, checkout.selectedPrice?.pricePerAdult, checkout.selectedPrice?.pricePerAdultUsd, checkout.selectedPrice?.pricePerChild, checkout.selectedPrice?.pricePerChildUsd, checkout.selectedPrice?.pricePerTrip, checkout.selectedPrice?.pricePerTripUsd]
  );

  const bookingRequest: BookingRequest = useMemo(
    () => ({
      adults: checkout.adultCount,
      children: checkout.childCount,
      date: checkout.selectedDate,
      endTime: checkout.timeSlot?.endTime,
      guestName: checkout.guestName || '',
      hotelId: checkout.hotel?.id,
      infants: checkout.infantCount,
      offerId: checkout.offer?.id,
      email: checkout.userEmail,
      paymentType: 1,
      roomNumber: checkout.userRoomNumber,
      startTime: checkout.timeSlot?.startTime,
      timeSlotId: checkout.timeSlot?.id,
      tourId: checkout.tourId,
      phone: checkout.userPhone,
      totalCost: aedAmount || 0,
      totalCostUsd: amountUsd,
      currency: 'aed'
    }),
    [checkout, amount, amountUsd, aedAmount]
  );

  const ticketOnClickHandler = useCallback(() => history.push(routes.ticket + "?tourId=" + checkout.tourId, checkout), [checkout, history]);

  // Effects
  useEffect(() => {
    const _stripePromise = loadStripe(stripePubKey);
    setStripePromise(_stripePromise);
  }, []);

  useEffect(() => {
    if (document.activeElement instanceof HTMLElement) {
      document.activeElement.blur();
    }
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    if (checkout.offer && !checkout.offer.isDirectReporting) {
      const fetchData = async () => {
        const data = await ticketApi.getHotels(50);
        setHotels(
          data.map((item) => ({
            label: `${item.name} (${item.cityName})`,
            value: item.id,
            hotel: item,
          }))
        );
      };
      fetchData();
    }
    if (!location.state) {
      history.replace(routes.ticket);
    }
    const data = location.state as CheckoutData;
    setCheckout(data);
  }, [checkout.offer, checkout.offer?.isDirectReporting, history, location.state]);

  useEffect(() => {
    const fetch = async () => {
      const data = await citiesApi.getCities(50, language);
      setCities(data);
    };
    fetch();
  }, []);
  useEffect(() => {
    const fetch = async () => {
      const data = await bookingApi.convertCurrency(amount, currency, 'aed');
      setAedAmount(Math.round(data));
    };
    if (amount > 0) {
      if (currency.toLowerCase()=='aed') {
        setAedAmount(amount);
      } else {
        fetch();
      }
    }
  }, [amount]);

  useEffect(() => {
    document.addEventListener(
      'eventHeaderClick',
      () => {
        ticketOnClickHandler();
      },
      false
    );

    return () =>
      document.removeEventListener(
        'eventHeaderClick',
        () => {
          ticketOnClickHandler();
        },
        false
      );
  }, [ticketOnClickHandler]);

  useEffect(() => {
    if(rubPayPageUrl && amount > 0) {
      (async () => {
        const fee = await bookingApi.getFee(amount, currency);
        setFee(fee)
      })()
    }
  }, [amount]);
  const MasterCard = () => (
    <img className="mastercard" src={`${assetsUrl}/payment-mastercard.png`} alt=''/>
  );
  const Visa = () => (
      <img className="visa" src={`${assetsUrl}/payment-visa.png`} alt=''/>
  );
const ModuleBank = () => (
  <img className="modulbank" src={`${assetsUrl}/payment-modulbank.png`} alt=''/>
  );
const Mir = () => (
  <img src={`${assetsUrl}/payment-mir.png`} alt=''/>
  );
  // Callbacks
  const setFieldCheckoutHandler = useCallback((name: string, value: any) => {
    setCheckout((prev) => {
      if (prev) {
        let newState = { ...prev };
        // @ts-ignore
        newState[name] = value;
        return newState;
      }
      console.log('setFieldCheckoutHandler prev is null');
      return initialCheckout;
    });
    setIsFormValid((prev) => {
      let newState = { ...prev };
      if (name === 'userEmail') {
        // @ts-ignore
        newState[name] = !!validateEmail(value || '');
      } else if (name === 'hotel') {
        const hotel = hotels.find((item) => item.hotel === value);
        if (hotel) {
          newState[name] = checkout.offer?.cities.some((item) => Number(item) === hotel?.hotel.cityId) || false;
        } else {
          setFieldCheckoutHandler('pickUpLocation', value);
          newState[name] = !!value;
        }
      } else {
        // @ts-ignore
        newState[name] = !!value;
      }
      return newState;
    });

  }, [checkout.offer?.cities, hotels]);

  useEffect(() => {
    if (checkout.timeSlot && checkout.hotel) {
      const fetchData = async () => {
        const data = await ticketApi.getPickUp(
          checkout.timeSlot?.pickUpTimeId,
          checkout.hotel?.id,
          checkout.timeSlot?.startTime
        );
        setFieldCheckoutHandler('pickUpTime', data);
      };
      fetchData();
    }
  }, [checkout.hotel, checkout.timeSlot, setFieldCheckoutHandler]);

  const isValid = !!validateEmail(checkout.userEmail || '') && !!checkout.userPhone && !!checkout.guestName && (!!checkout.hotel || !!checkout.offer?.isDirectReporting);

  const createRubBookingFromSbp = async () => {
    if(Fee){
      setProcessingSbp(true)
      const booking = await bookingApi.createBooking({ ...bookingRequest, totalCost: Fee.amount, currency:'rub' })
      if(booking){
        window.location.href = `${rubPayPageUrl}/${booking.guid}?paytype=sbp&successurl=${window.location.host}/confirm/${booking.guid}`
      }
      setProcessingSbp(false)
    }
  }
  const createRubBookingFromCard = async () => {
    if(Fee){
      setProcessingCard(true)
      const booking = await bookingApi.createBooking({ ...bookingRequest, totalCost: Fee.amount, currency:'rub' })
      if(booking){
        window.location.href = `${rubPayPageUrl}/${booking.guid}?paytype=card&successurl=${window.location.host}/confirm/${booking.guid}`
      }
      setProcessingCard(false)
    }
  }
  const methods = useMemo(() => {
    const methodsArray = [];

    if (rubPayPageUrl && Fee && aedAmount) {
      methodsArray.push({
        name: t('payment.ruCardPaymentTitle'),
        type: 0,
        icon: [<ModuleBank />],
        description: t('payment.ruCardPaymentDescription'),
        render: (
          <div>
            <div className="payment-button-container">
              <Button
                disabled={!isValid}
                onClickHandler={createRubBookingFromSbp}
                iconLeft={() =>
                  processingSbp ? <Loader type="Circles" color="#FFF" height={20} width={20} /> : null
                }
                label={t('payment.bookNowSbp', { amount: Fee.totalCost.toFixed(2), currency: 'RUB' })}
                size="medium"
                color="blue"
                fullWidth
              />
              <Button
                disabled={!isValid}
                onClickHandler={createRubBookingFromCard}
                iconLeft={() =>
                  processingCard ? <Loader type="Circles" color="#FFF" height={20} width={20} /> : null
                }
                label={t('payment.bookNowCard', {
                  amount: ((Fee.totalCost || 0) + (Fee.totalCost || 0) * 0.02).toFixed(2),
                  currency: 'RUB',
                })}
                size="medium"
                color="blue"
                fullWidth
              />
            </div>
          </div>
        ),
      });
    }

    if (aedAmount) {
      methodsArray.push({
        name: t('payment.masterCardAndVisa'),
        type: 1,
        icon: [<MasterCard />, <Visa />],
        description: t('payment.masterCardAndVisaDescription'),
        render: (
          <div>
            {stripePromise && aedAmount && (
              <Elements stripe={stripePromise}>
                <Payment
                  request={bookingRequest}
                  amount={aedAmount}
                  ticketType={checkout.offer?.name || ''}
                  isValid={isValid}
                />
              </Elements>
            )}
          </div>
        ),
      });
    }

    return methodsArray;
  }, [aedAmount, Fee, rubPayPageUrl, stripePromise, isValid, t, processingSbp, processingCard, bookingRequest, checkout.offer?.name ]);
  useEffect(() => {
    console.log('aedAmount updated:', aedAmount);
    console.log('methods array', methods.length);
    console.log('Fee updated:', Fee);
    console.log('checkout:', checkout);
  }, [aedAmount,methods,Fee, checkout]);
  return (
    <>
      <div className='checkout-container'>
        <h2 className='checkout-title'>{t('checkout.guest')}</h2>
        <div className='checkout-box-wrap checkout-box-details'>
          <form className='checkout-details-form'>
            {!checkout.offer?.isDirectReporting && (
              <>
                <SelectRS
                  onChange={(value) => setFieldCheckoutHandler('hotel', value.hotel)}
                  placeholder={t('checkout.selectHotel')}
                  options={filteredHotel}
                  onInputChange={setFilterSelect}
                  error={!isFormValid.hotel}
                />
                {!isFormValid.hotel && (
                  <div className="error">
                    {t('checkout.invalidHotel', {cities: cities?.filter(city => checkout.offer?.cities.includes(city.value)).map((city) => city.label)
                      .join(', '), cityName: checkout.hotel?.cityName, supportPhoneNumber:supportPhoneNumber})}
                  </div>
                )}
              </>
            )}

            <div className='form-row'>
              <div className={!checkout.offer?.isDirectReporting ? 'form-col' : 'form-col-full'}>
                <Input
                  type='text'
                  onChange={(event) => setFieldCheckoutHandler('guestName', event.target.value)}
                  value={checkout.guestName}
                  placeholder={t('checkout.enterYourName')}
                  error={!isFormValid.guestName}
                />
              </div>
              {!checkout.offer?.isDirectReporting && (
                <div className='form-col'>
                  <Input
                    type='text'
                    onChange={(event) => setFieldCheckoutHandler('userRoomNumber', event.target.value)}
                    value={checkout.userRoomNumber}
                    placeholder={t('checkout.enterYourRoom')}
                  />
                </div>
              )}
            </div>
            <div className='form-row'>
              <div className='form-col'>
                <Input
                  type='email'
                  onChange={(event) => setFieldCheckoutHandler('userEmail', event.target.value)}
                  value={checkout.userEmail}
                  placeholder={t('checkout.enterYourEmail')}
                  error={!isFormValid.userEmail}
                />
              </div>
              <div className='form-col'>
                <PhoneInput
                  country={'ae'}
                  enableAreaCodes={true}
                  onChange={(event) => setFieldCheckoutHandler('userPhone', event)}
                  placeholder={t('checkout.selectMobile')}
                  value={checkout.userPhone}
                  inputProps={{
                    name: 'userPhone',
                    required: true
                  }}
                  isValid={(value, country) => {
                    // @ts-ignore
                    return isFormValid.userPhone || (value && value !== country.countryCode);
                  }}
                />
              </div>
            </div>
            {!checkout.offer?.isDirectReporting && (
              <span className='checkout-summary-label'>
                {t('checkout.hotelNotListed', {supportPhoneNumber:supportPhoneNumber})}
              </span>
            )}
          </form>
        </div>

        <h2 className='checkout-title checkout-title_first'>{t('checkout.bookingSummary')}</h2>
        <div className='checkout-box-wrap checkout-box-summary'>
          <ul className='checkout-summary-list'>
            {checkout.offer?.bookingType !== 2 && (
              <li>
                <span className='checkout-summary-label'>{t('checkout.tourDate')}</span>
                {`${format(checkout.selectedDate || new Date(), 'dd.MM.yyyy')} (${
                  checkout.timeSlot && timeFormat(checkout.timeSlot.startTime, checkout.timeSlot.endTime)
                })`}
              </li>
            )}
            {!checkout.offer?.isDirectReporting && checkout.hotel && (
              <li>
                <span className='checkout-summary-label'>{t('checkout.pickUpTime')}</span>
                {checkout.pickUpTime
                  ? `${format(addMinutes(emptyDate, checkout.pickUpTime.timeShiftStart), 'HH-mm')} - ${format(
                    addMinutes(emptyDate, checkout.pickUpTime.timeShiftEnd),
                    'HH-mm'
                  )}`
                  : t('checkout.selectHotelFirst')}
              </li>
            )}
            <li>
              <span className='checkout-summary-label'>{t('checkout.ticketType')}</span>
              {checkout.offer?.name}
            </li>
            {checkout.offer?.bookingType === 2 &&
              checkout?.ticketStock?.validUntil && (
                <li>
                  <span className='checkout-summary-label'>{t('checkout.validUntil')}</span>
                  {format(new Date(checkout.ticketStock?.validUntil), 'dd.MM.yyyy')}
                </li>
              )}
            {
              !checkout.offer?.childrenAllowed &&
              <li>
                <span className='checkout-summary-label'>{t('checkout.numberOfPeopleLabel')}</span>
                {checkout.adultCount}
              </li>
            }
            {
              checkout.offer?.childrenAllowed &&
              <li>
                <span className='checkout-summary-label'>{t('checkout.numberOfPeopleLabel')}</span>
                {checkout.adultCount} {t('adults')} {checkout.childCount ? `/ ${checkout.childCount} ${t('child')}` : ''}{' '}
                {checkout.infantCount ? `/ ${checkout.infantCount} ${t('infant')}` : ''}
              </li>
            }
            <li>
              <span className='checkout-summary-label'>{t('checkout.cancellationPolicyLabel')}</span>
              {checkout.cancellationPolicy && (checkout.cancellationPolicy
                ?.sort((a, b) => (a.hours < b.hours ? 1 : -1))
                .map((item, index) => (
                  <>
                    {index > 0 && <br />}{' '}
                    {`${item.hours} ${declOfNum(item.hours, [t('checkout.clock1'), t('checkout.clock2'), t('checkout.clock3')])} ${
                      t('checkout.orMore')
                    } = ${item.percents}% ${t('checkout.returns')}`}
                  </>
                )))}
              {checkout.cancellationPolicy && checkout.cancellationPolicy.length === 0 && (
                t('checkout.nonRefundable')
              )}
            </li>
            <li>
              <span className='checkout-summary-label'>{t('checkout.total')}</span>
              {Math.round(amount) + ' ' + currency}
            </li>
          </ul>
        </div>
        <div className="checkout-box-wrap checkout-box-payment">
        {methods.map((item) => (
          <div className="payment-select-item" key={item.type}>
            <div className="payment-select-title" onClick={() => setPaymentType(item.type)}>
              {methods.length > 1 && (
                <div
                  className={`payment-radio-flag ${
                    paymentType === item.type ? 'payment-radio-flag_is-active' : ''
                  }`}
                />
              )}
              {item.icon.map((icon, index) => (
                <React.Fragment key={index}>{icon}</React.Fragment>
              ))}
              <div className="payment-description">
                <h5>{item.name}</h5>
                <span>{item.description}</span>
              </div>
            </div>
            <div className="payment-select-body">{paymentType === item.type ? item.render : null}</div>
          </div>
        ))}
          {checkout.faqList && (
            <Accordeon items={getFaq(checkout.faqList)} />
          )}
        </div>
        <div className='checkout-box-wrap'>
          <div className="payment-description">
              <span>{t('payment.purchaseAgreement')}</span>
          </div>
        </div>
      </div>
    </>
  );
};

export default Checkout;

const getFaq = (faqList: FaqType[]): AccordeonItemType[] => {
  const faq = [];
  for (let entry of faqList) {
    faq.push(
      {
        title: entry.question,
        body: entry.answer
      }
    )
  }
  return faq;
};

