import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { routes } from '../../config/constants';
import Layout from './layout';
import EmbeddedLayout from './embedded-layout';
import ScrollToTop from './scroll-to-top';
import React from 'react';
import { ToastContainer } from 'react-toastify';

const Routes = () => (
  <Router>
    <ScrollToTop />
    <Switch>
      <Route path={routes.embeddedTicket} component={EmbeddedLayout} />
      <Route path={routes.main} component={Layout} />
      {/* <Route component={NotFound} /> */}
    </Switch>
    <ToastContainer
      position='top-right'
      autoClose={5000}
      hideProgressBar
      newestOnTop={false}
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      draggable
      pauseOnHover
    />
  </Router>
);

export default Routes;
