import { useMemo } from 'react';
import isEqual from 'date-fns/isEqual';
import { addMinutes, startOfDay } from 'date-fns';
import { TimeSlotType } from '../../types/ticket';
import { Availability } from '../../types/availability';

const useCarouselTimeSlot = (
  availabilities: Availability[],
  utcOffset: number,
  timeSlots?: TimeSlotType[],
  selectedDate?: Date
) => {
  const timeSlotsCarouselItems = useMemo(() => {
    if (selectedDate) {
      const currentAvailabilities = availabilities.filter(
        (availability) => selectedDate && isEqual(startOfDay(new Date(availability.date)), startOfDay(selectedDate))
      );
      return timeSlots?.reduce((acc: { timeSlot: TimeSlotType; availability?: Availability }[], item) => {
        if (item.isAnyTime) {
          currentAvailabilities
            .filter((currentAvailability) => currentAvailability.timeSlotId === item.id && currentAvailability.left > 0)
            .forEach((currentAvailability) => {
              const availabilityStartDate = new Date(currentAvailability.startTime || '');
              const availabilityEndDate = new Date(currentAvailability.endTime || '');
              if (
                !acc.some(
                  (row) =>
                    selectedDate &&
                    isEqual(availabilityStartDate, addMinutes(startOfDay(selectedDate), Number(row.timeSlot.startTime)))
                )
              ) {
                acc.push({
                  timeSlot: {
                    ...item,
                    startTime: availabilityStartDate.getHours() * 60 + availabilityStartDate.getMinutes(),
                    endTime: availabilityEndDate.getHours() * 60 + availabilityEndDate.getMinutes(),
                    availability: currentAvailability
                  },
                  availability: currentAvailability
                });
              }
            });
        } else if (!acc.some((row) => row.timeSlot.startTime === item.startTime)) {
          const availability = currentAvailabilities.find(
            (availability) =>
              selectedDate &&
              availability.timeSlotId === item.id &&
              isEqual(
                new Date(availability.startTime || ''),
                addMinutes(startOfDay(selectedDate), Number(item.startTime))
              )
          );
          if(availability && availability.left > 0)
          acc.push({
            timeSlot: item,
            availability: availability
          });
        }
        return acc;
      }, []);
    } else return [];
  }, [selectedDate, timeSlots, availabilities]);

  return timeSlotsCarouselItems;
};

export default useCarouselTimeSlot;
