import React, { useEffect } from 'react';
import Routes from './containers/router';
import './assets/styles/app.sass';
import ViewportProvider from './contexts/viewport-context';
import { useTranslation } from 'react-i18next';
import { language, seoTitle, seoDescription, googleAdsID, googleAnalyticsID, inspectletID } from './config/constants';
import { Helmet } from 'react-helmet';
import { getFBTrackingCode, getGoogleAdsCode, getGoogleAnalyticsCode, getNoScriptFBTrackingCode, getYandexTrackingCode } from './lib/utils';

function App() {
  const { i18n } = useTranslation();

  useEffect(() => {
    i18n.changeLanguage(language);
  }, [i18n]);

  return (
    <>
      <Helmet>
        <title>{seoTitle}</title>
        <meta name="description" content={seoDescription} />
        {/* Add Google Analytics Script */}
        {googleAdsID && (
           <script async src={`https://www.googletagmanager.com/gtag/js?id=${googleAdsID}`}></script>
        )}
        <script>
        {getGoogleAdsCode()}
        </script>
        {googleAnalyticsID && (
           <script async src={`https://www.googletagmanager.com/gtag/js?id=${googleAnalyticsID}`}></script>
        )}
        <script>
        {getGoogleAnalyticsCode()}
        </script>
        <script>
        {getFBTrackingCode()}
        </script>
        <script>
        {getNoScriptFBTrackingCode()}
        </script>
        <script>
        {getYandexTrackingCode()}
        </script>
        {/* Add any other dynamic scripts like Inspectlet */}
        {inspectletID && (
          <script type="text/javascript">{`
          (function() {
            window.__insp = window.__insp || [];
            __insp.push(['wid', ${inspectletID}]);
            var ldinsp = function(){
              if(typeof window.__inspld != "undefined") return; window.__inspld = 1;
              var insp = document.createElement('script');
              insp.type = 'text/javascript'; insp.async = true; insp.id = "inspsync";
              insp.src = ('https:' == document.location.protocol ? 'https' : 'http') +
              '://cdn.inspectlet.com/inspectlet.js?wid=${inspectletID}&r=' + Math.floor(new Date().getTime()/3600000);
              var x = document.getElementsByTagName('script')[0];
              x.parentNode.insertBefore(insp, x);
            };
            setTimeout(ldinsp, 0);
          })();
        `}</script>
        )}
      </Helmet>
      <ViewportProvider>
        <Routes />
      </ViewportProvider>
    </>
  );
}

export default App;
