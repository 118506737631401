import configs from '../configs';

const getHostname = () => {
  let hostname = window.location.hostname;
  if (hostname.startsWith('www.')) {
    hostname = hostname.slice(4);
  }
  return hostname;
};

const hostname = getHostname();

const config = configs[hostname]|| configs['default'];

if (!config) {
  console.error(`No configuration found for hostname: ${hostname}`);
  // You might want to redirect to an error page or use a default configuration
}

export const routes = {
  main: '/',
  ticket: '/',
  embeddedTicket: '/embedded',
  tour: '/tour',
  checkout: '/checkout',
  embeddedCheckout: '/embedded/checkout',
  confirm: '/confirm',
};

export const stripePubKey = process.env.REACT_APP_STRIPE_PUB_KEY || '';

export const appName = config?.appName || '';

export const supportPhoneNumber = config?.supportPhoneNumber || '';

export const supportEmail = config?.supportEmail || '';

export const mainSite = config?.mainSite || '';

export const pixelID = config?.pixelID;

export const googleAdsID = config?.googleAdsID;

export const googleAnalyticsID = config?.googleAnalyticsID;

export const googleConversionActionID = config?.googleConversionActionID;

export const yandexMetricID = config?.yandexMetricID;

export const yandexConversionActionID = config?.yandexConversionActionID;

export const inspectletID = config?.inspectletID;

export const agencyGuid = config?.agencyKey || '';

export const apiUrl = config?.apiUrl || '';

export const tourId = config?.tourId || '';

export const showPhone = config?.showPhone || false;

export const emptyDate = new Date(2020, 1, 1, 0, 0, 0);

export const language = config?.language || 'en';

export const rubPayPageUrl = config?.rubPayPageUrl;

export const currency = config?.currency || 'AED';

export const seoTitle = config?.seoTitle || '';

export const seoDescription = config?.seoDescription || '';
