import { faPhone } from "@fortawesome/fontawesome-free-solid";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import { faEnvelopeOpen } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { supportPhoneNumber, supportEmail, showPhone, rubPayPageUrl } from '../../config/constants';
import { Link } from "react-router-dom";
import {routes } from "../../config/constants";

const Footer = () => {
    const assetsUrl="https://traveldeskuae.blob.core.windows.net/assets"
    return (
      <footer id="main-footer">
        <div className="footer-wrap">
        <div className="footer-column footer-contacts">
            <div className="footer-column-center-wrapper">
            {showPhone && (
              <a href={`tel:${supportPhoneNumber}`}><FontAwesomeIcon icon={faPhone as IconProp} /> <span>{supportPhoneNumber.substring(0, 4)}</span> {supportPhoneNumber.substring(4)}</a>
            )}
              <a target="_blank" href={`mailto:${supportEmail}`} rel="noreferrer">
                <FontAwesomeIcon icon={faEnvelopeOpen as IconProp} />  {supportEmail}
              </a>
            </div>
          </div>
          <div className="footer-column footer-logos">
            {rubPayPageUrl && (
              <div className="footer-logo-wrapper">
                <img className="payment-card-logo payment-card-logo-mir" src={`${assetsUrl}/payment-mir.png`} alt=''/>
                <img className="payment-card-logo" src={`${assetsUrl}/payment-maestro.png`} alt=''/>
              </div>
            )}
            <div className="footer-logo-wrapper">
              <img className="payment-card-logo" src={`${assetsUrl}/payment-visa.png`} alt=''/>
              <img className="payment-card-logo" src={`${assetsUrl}/payment-mastercard.png`} alt=''/>
            </div>
            {rubPayPageUrl && (
            <div className="footer-logo-wrapper">
              <img className="payment-card-logo payment-card-logo-modulbank" src={`${assetsUrl}/payment-modulbank.png`} alt=''/>
            </div>
            )}
          </div>
          <div className="footer-column footer-logos">
            <div className="footer-logo-wrapper">
             <a href="https://traveldesk.me/" target="_blank" rel="noopener noreferrer">
              <div className='footer-photo'></div>
            </a>
            <span className='footer-copyright'>© 2024 All Rights Reserved</span>
            </div>
          </div>
        </div>
      </footer>
    );
  }
  export default Footer