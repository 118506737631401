import { ButtonBack, ButtonNext, CarouselProvider, Slide, Slider } from 'pure-react-carousel';
import { ReactComponent as IconDropdownLarge } from '../../assets/images/icons/dropdown-large.svg';
import isEqual from 'date-fns/isEqual';
import { addMonths, startOfDay, startOfMonth } from 'date-fns';
import format from 'date-fns/format';
import { ReactComponent as IconChecked } from '../../assets/images/icons/icon-checked.svg';
import { ReactComponent as IconClose } from '../../assets/images/icons/icon-close.svg';
import DatePickerComponent from '../date-picker';
import * as React from 'react';
import { forwardRef, useCallback, useLayoutEffect, useMemo, useRef, useState } from 'react';
import { language } from '../../config/constants';
import ru from "date-fns/locale/ru";
import en from "date-fns/locale/en-US";


type CarouselProps = {
  carouselItems: { date: Date; isExternalTour: boolean; isEnable: boolean }[];
  selectedDate?: Date;
  onChange: (date: Date) => void;
  excludeDates: Date[];
  isExternalTour: boolean;
  fetchAvailability: (date: Date, to: Date) => Promise<void>;
};

const Carousel = ({ carouselItems, selectedDate, excludeDates, onChange,   isExternalTour,
                    fetchAvailability, }: CarouselProps) => {
  const [disableAnimation, setDisableAnimation] = useState(false);
  const [isLoading, setIsLoading] = useState(false);


  const buttonNextRef = useRef(null);

  const handleChangeDate = useCallback(
    (value) => {
      onChange(value);
    },
    [onChange]
  );

  const currentSlide = useMemo(() => {
    if (!selectedDate) {
      return 0;
    }
    const currentPosition =
      selectedDate &&
      carouselItems.findIndex((item) => isEqual(startOfDay(new Date(item.date)), startOfDay(selectedDate)));

    if ((currentPosition || currentPosition === 0) && currentPosition >= 0) {
      return currentPosition < 1
        ? currentPosition
        : currentPosition < carouselItems.length - 2 || currentPosition < carouselItems.length - 1
          ? currentPosition - 1
          : currentPosition - 2;
    }
    return carouselItems.length - 3;
  }, [selectedDate, carouselItems]);

  useLayoutEffect(() => {
    setTimeout(() => setDisableAnimation(false), 0);
    setDisableAnimation(true);
  }, [carouselItems]);

  const CustomInput = forwardRef((props: React.HTMLProps<HTMLInputElement>, ref: React.Ref<HTMLInputElement>) => (
    <ButtonNext
      // @ts-ignore
      ref={buttonNextRef}
      disabled={false}
      onClick={() => {
        if (
          buttonNextRef.current &&
          // @ts-ignore
          buttonNextRef.current.state.currentSlide + buttonNextRef.current.state.visibleSlides >=
          // @ts-ignore
          buttonNextRef.current.state.totalSlides
        ) {
          if (props.onClick) {
            // @ts-ignore
            props.onClick();
          }
        }
      }}
      className='carousel__button carousel__button_next'
    >
      <IconDropdownLarge />
    </ButtonNext>
  ));

  return (
    <CarouselProvider
      naturalSlideWidth={95}
      naturalSlideHeight={63}
      totalSlides={carouselItems.length}
      visibleSlides={3}
      step={2}
      className='carousel'
      currentSlide={currentSlide}
      disableAnimation={disableAnimation}
    >
      <ButtonBack className='carousel__button carousel__button_back'>
        <IconDropdownLarge />
      </ButtonBack>
      <Slider className='carousel__slider'>
        {carouselItems.map((carouselItem, index) => {
          const selectedCurrentDate = selectedDate && isEqual(startOfDay(carouselItem.date), startOfDay(selectedDate));
          // @ts-ignore
          return (
            <Slide key={index} index={index} onClick={() => (carouselItem.isEnable || carouselItem.isExternalTour) && onChange(carouselItem.date)}>
              <div
                className={`carousel__item ${selectedCurrentDate ? ' carousel__item_active' : ''} ${
                  carouselItem.isEnable || carouselItem.isExternalTour ? '' : ' carousel__item_disabled'
                }`}
              >

                <span>
                  {format(carouselItem.date,
                    'iii',
                    // @ts-ignore
                    {locale: language === 'ru' ? ru : en})}
                </span> {
                format(carouselItem.date,
                  'd MMM',
                  // @ts-ignore
                  {locale: language === 'ru' ? ru : en})}
                {!carouselItem.isExternalTour &&
                <>{carouselItem.isEnable ? <IconChecked/> :
                  <IconClose/>}</>}
              </div>
            </Slide>
          );
        })}
      </Slider>
      <DatePickerComponent
        render={<CustomInput />}
        selected={selectedDate}
        isLoading={isLoading}
        value={selectedDate && format(selectedDate, 'dd.MM.yyyy')}
        onChange={handleChangeDate}
        excludeDates={excludeDates}
        minDate={new Date()}
        onMonthChange={async (val) => {
          setIsLoading(true);
          const start = startOfMonth(val);
          !isExternalTour && (await fetchAvailability(start, addMonths(start, 1)));
          setIsLoading(false);
        }}
        onCalendarOpen={async () => {
          setIsLoading(true);
          const start = carouselItems[carouselItems.length - 1]?.date;
          !isExternalTour && (await fetchAvailability(start, addMonths(start, 1)));
          setIsLoading(false);
        }}
        openToDate={carouselItems[carouselItems.length - 1]?.date}
      />
    </CarouselProvider>
  );
};

export default Carousel;
