import ReactTooltip from 'react-tooltip';
import { currency } from '../../config/constants';

type NumberInputProps = {
  label: string;
  tooltip?: string;
  price?: number;
  delPrice?: number;
  count?: number;
  minValue?: number;
  maxValue?: number;
  onChange: (value: number) => void;
};

const NumberInput = ({
  label,
  tooltip,
  price,
  delPrice,
  minValue = 0,
  maxValue = 15,
  count = 0,
  onChange
}: NumberInputProps) => {
  const counterChangeHandler = (newValue: number) => {
    if (newValue >= minValue && newValue <= maxValue) {
      onChange(newValue);
    }
  };

  return (
    <div className='number-input-container'>
      <div className='number-input-label'>
        <h6>
          {label}
          <span data-tip data-for={label}>
            i
          </span>
          <ReactTooltip id={label} place='top' type='info' effect='float' multiline={true}>
            {tooltip}
          </ReactTooltip>
        </h6>
        {currency} {(price || 0).toFixed(0)} {delPrice && <del>{currency} {delPrice}</del>}
      </div>
      <div className='counter-container'>
        <button
          className='counter-container__button counter-container__button_left'
          onClick={counterChangeHandler.bind(undefined, count - 1)}
        >
          -
        </button>
        <input type='number' className='counter-container__input' value={count} />
        <button
          className='counter-container__button counter-container__button_right'
          onClick={counterChangeHandler.bind(undefined, count + 1)}
        >
          +
        </button>
      </div>
      <div className='number-input-price'>{(count * (price || 0)).toFixed(0)} {currency}</div>
    </div>
  );
};

export default NumberInput;