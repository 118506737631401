import { useState, useEffect} from "react";
import isEqual from "date-fns/isEqual";
import { isWithinInterval, startOfDay } from 'date-fns';
import { Availability } from '../types/availability';
import {
    BookingTypeEnum,
    CheckoutData,
    OfferType,
    PricingPeriods,
    TicketType,
    TimeSlotType
} from '../types/ticket';
import { ticketApi } from '../lib/api';
import { useLocation } from 'react-router-dom';
import { flushSync } from 'react-dom';

export const useOffer = (tour: TicketType | undefined, availabilities: Availability[], setLoader: (val: boolean) => void, setLoaderTimeSlot: (val: boolean) => void, currency: string, fetchAvailability: (offerId: number, date: Date | undefined, isExternal: boolean) => Promise<Availability[]>) => {
    const [editOfferObj, setEditOfferObj] = useState(emptyOfferObj)
    const location = useLocation();

    useEffect(() => {
        if (location.state && tour) {
            const data = location.state as CheckoutData;
            flushSync(() => {
                setEditOfferObj(data);
            })
            flushSync(() => {
                if(data?.offer?.id){
                    selectOffer(data.offer.id, true)
                }
            });
        }
        // eslint-disable-next-line
    }, [location.state, tour]);

    const clear = () => setEditOfferObj(emptyOfferObj);


    const getAvailability = (timeSlots: TimeSlotType[], date?: Date) => availabilities.find(
        (item) =>
            timeSlots.some((slot) => slot.id === item.timeSlotId) && date &&
            isEqual(startOfDay(date), startOfDay(new Date(item.date)))
    );

    const selectOffer = async (id: number, notOldCheck?: boolean) => {
        if (id === editOfferObj?.offer?.id && !notOldCheck) {
            setEditOfferObj((prev) => ({...prev, offer: undefined}));
        } else {
            const offer = tour?.offers?.find((offer: OfferType) => offer.id === id);
            if (!offer) {
                return;
            }
            const result = {
                ...editOfferObj,
                ticketName: tour?.name || '',
                tourId: tour?.id,
                offer: offer,
                timeSlot: offer?.timeSlots[0],
                availability: offer?.timeSlots && !offer.isExternal ? getAvailability(offer.timeSlots, editOfferObj.selectedDate) : undefined,
                faq: tour?.faq,
                cancellationPolicy: tour?.cancellationPolicy,
            };

            setEditOfferObj(result);

            if (offer.isExternal) {
                result.selectedDate = undefined;
            }
            if (offer.id && offer.bookingType === BookingTypeEnum.TicketOnly) {
                setLoader(true)
                result.ticketStock = await ticketApi.getTicketStock(offer.id);
                setLoader(false)
            } else if (!offer.isExternal && !availabilities.some((item) => item.offerId === offer.id)) {
                availabilities = await fetchAvailability(offer.id, editOfferObj.selectedDate, offer.isExternal)
            }

            if (editOfferObj.selectedDate && (!result.availability || result.availability.left < 1)) {
                const newAvailability = availabilities.find(
                    (item) => offer?.timeSlots.some((slot) => slot.id === item.timeSlotId) && item.left > 0
                );
                if (newAvailability) {
                    result.availability = newAvailability;
                    result.selectedDate = new Date(newAvailability.date);
                }
            }
            if (result.selectedDate) {
                result.selectedPrice = getPrice(offer.pricingPeriods, result.selectedDate)
            } else {
                result.selectedPrice = undefined;
            }

            if (offer?.bookingType === BookingTypeEnum.TicketOnly) {
                result.adultCount = 1;
                result.childCount = 0;
                result.infantCount = 0;
                result.selectedDate = new Date();
                result.selectedPrice = offer.pricingPeriods && offer.pricingPeriods[0];
            }

            if (
                editOfferObj.offer &&
                offer &&
                (editOfferObj.offer.maxNumOfPeople > offer.maxNumOfPeople ||
                    (result.availability &&
                        result.availability.left < result.adultCount + result.childCount + result.infantCount))
            ) {
                result.adultCount = 1;
                result.childCount = 0;
                result.infantCount = 0;
            }
            if (
                result.availability &&
                result.availability.leftAdultsTickets &&
                result.availability.leftAdultsTickets < result.adultCount
            ) {
                result.adultCount = 1;
            }
            if (
                result.availability &&
                result.availability.leftChildrenTickets &&
                result.availability.leftChildrenTickets < result.childCount
            ) {
                result.childCount = 0;
            }
             setEditOfferObj({ ...result })
        }
    }

    const selectDate = async (date: Date) => {
        setEditOfferObj((prev) => ({
            ...prev,
            selectedDate: date,
        }));
        if (editOfferObj.offer && date) {
            if (editOfferObj.offer.isExternal) {
                await fetchAvailability(editOfferObj.offer.id, date, true);
            }
            let price = getPrice(editOfferObj?.offer.pricingPeriods, date);

            setEditOfferObj((prev) => {
                const result = {
                    ...prev,
                    selectedDate: date,
                    selectedPrice: price,
                    availability:
                      !prev.offer?.isExternal && prev.offer?.timeSlots ? getAvailability(prev.offer.timeSlots, date) : undefined,
                    timeSlot: prev.offer?.isExternal && prev.timeSlot?.isAnyTime ? undefined : prev.timeSlot,
                }
                if (result.offer &&
                    (result.availability &&
                      result.availability.left < result.adultCount + result.childCount + result.infantCount)
                ) {
                    result.adultCount = 1;
                    result.childCount = 0;
                    result.infantCount = 0;
                }
                if (
                  result.availability &&
                  result.availability.leftAdultsTickets &&
                  result.availability.leftAdultsTickets < result.adultCount
                ) {
                    result.adultCount = 1;
                }
                if (
                  result.availability &&
                  result.availability.leftChildrenTickets &&
                  result.availability.leftChildrenTickets < result.childCount
                ) {
                    result.childCount = 0;
                }
                return result
            });
        }
    };

    const selectTimeSlot = (slot: TimeSlotType) => {
        setEditOfferObj((prevState) => {
                if (slot && slot.availability) {
                    const result = {...prevState, availability: slot.availability, timeSlot: slot};
                    if (slot.availability.left < prevState.adultCount + prevState.childCount + prevState.infantCount) {
                        result.adultCount = 1;
                        result.childCount = 0;
                        result.infantCount = 0;
                    }
                    if (slot.availability.leftAdultsTickets && slot.availability.leftAdultsTickets < prevState.adultCount) {
                        result.adultCount = 1;
                    }
                    if (slot.availability.leftChildrenTickets && slot.availability.leftChildrenTickets < prevState.childCount) {
                        result.childCount = 0;
                    }
                    if (slot.availability.left < 1) {
                        console.error('slot.availability?.left < 1')
                        // const newAvailability = availabilities.find(
                        //     (item) => prevState?.offer?.timeSlots.some((slot) => slot.id === item.timeSlotId) && item.left > 0
                        // );
                        // if (newAvailability) {
                        //     result.availability = newAvailability;
                        //     result.selectedDate = new Date(newAvailability.date);
                        //     result.selectedPrice = prevState?.offer?.prices.find(
                        //         (price) =>
                        //             result.selectedDate && isEqual(startOfDay(result.selectedDate), startOfDay(new Date(price.date)))
                        //     );
                        // }
                    }
                    return result;
                }
                return prevState;
            }
        );
    }

    const handleChangePeopleCount = (value: number, field: 'adultCount' | 'childCount' | 'infantCount') => {
        if (editOfferObj && editOfferObj.offer?.bookingType !== BookingTypeEnum.TicketOnly) {
            const totalPeople =
                editOfferObj.adultCount +
                (editOfferObj?.offer?.childrenAllowed ? editOfferObj.childCount : 0) +
                (editOfferObj?.offer?.infantsAllowed ? editOfferObj.infantCount : 0);
            if (editOfferObj?.offer?.maxNumOfPeople && totalPeople >= editOfferObj?.offer?.maxNumOfPeople && editOfferObj[field] < value) {
                return;
            }

            if (
                editOfferObj.availability &&
                totalPeople >= editOfferObj.availability?.left &&
                editOfferObj[field] < value
            ) {
                return;
            }
            if (
                field === 'adultCount' &&
                editOfferObj.availability?.leftAdultsTickets &&
                value > editOfferObj.availability?.leftAdultsTickets &&
                editOfferObj[field] < value
            ) {
                return;
            }
            if (
                field === 'childCount' &&
                editOfferObj.availability?.leftChildrenTickets &&
                value > editOfferObj.availability.leftChildrenTickets &&
                editOfferObj[field] < value
            ) {
                return;
            }
        } else if (editOfferObj && editOfferObj.offer?.bookingType === BookingTypeEnum.TicketOnly) {
            if (
                field === 'adultCount' &&
                editOfferObj.ticketStock?.adultTicketsLeft &&
                value > editOfferObj.ticketStock?.adultTicketsLeft &&
                editOfferObj[field] < value
            ) {
                return;
            }
            if (
                field === 'childCount' &&
                editOfferObj.ticketStock?.childTicketsLeft &&
                value > editOfferObj.ticketStock.childTicketsLeft &&
                editOfferObj[field] < value
            ) {
                return;
            }
        }
        setEditOfferObj(prevState => ({...prevState, [field]: value}));
    };

    return {editOfferObj, selectOffer, handleChangePeopleCount, selectDate, selectTimeSlot, clear}
}

const emptyOfferObj: CheckoutData = {
    offer: undefined,
    ticketName: '',
    adultCount: 1,
    childCount: 0,
    infantCount: 0,
};

const getPrice = (prices: PricingPeriods[], date: Date) =>
  prices.find((price) => isWithinInterval(startOfDay(date), {
      start: startOfDay(new Date(price.dateStart)),
      end: startOfDay(new Date(price.dateEnd))
  }));
